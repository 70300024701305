import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../../store";
import { TrackingTile } from "../../../../../../BronzeGroupTracking/BGTTracking/TrackingTile/TrackingTile";
import { FetchBronzeGroupTrackingOverviewData } from "../../../../../../BronzeGroupTracking/BronzeGroupTrackingDataLayer";
import { GraduatedTile } from "../../../../../../BronzeGroupTracking/BGTGraduated/GraduatedTile/GraduatedTile";
import "./ABOProfileBGT.scss";
import LeafProfileLoader from "../../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { setActiveTabView } from "../../../../../../../reducers/bronzeGroupTrackingTab";
import { updateSearchKeyword as updateTrackingSearchKeyword } from "../../../../../../../reducers/BronzeGroupTracking/tracking/filter";
import { updateSearchKeyword as updateGraduatedSearchKeyword } from "../../../../../../../reducers/BronzeGroupTracking/graduated/filter";
import { BGT_TABS } from "../../../../../../../common/enums/bronze-group-tracking-enums";
import { ROUTE_PATHS } from "../../../../../../../common/enums/routing-enums";
import { hideProfile } from "../../../../../../../reducers/profile";
import { AboTileData } from "../../../../../../../services/BronzeGroupTracking/bronzeGroupTracking.types";

const ABOProfileBGT = () => {
  const viewState = "mobile";

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const targetAffAbo = useAppSelector((state) => state.profile.affAbo);

  const { isLoading, bronzeTrackingData } = FetchBronzeGroupTrackingOverviewData(selectedPeriod, targetAffAbo);
  const { trackingTilesData = [], graduatedTilesData = [] } = bronzeTrackingData.overview.awardProgress;

  const handleClick = (bgtTab: BGT_TABS, abo: AboTileData) => {
    const { aboNumber } = abo;

    dispatch(setActiveTabView(bgtTab));
    dispatch(updateTrackingSearchKeyword(aboNumber));
    dispatch(updateGraduatedSearchKeyword(aboNumber));
    dispatch(hideProfile());
    navigate(ROUTE_PATHS.BRONZE_GROUP_TRACKING);
  };

  return (
    <div className="abo-profile-bgt">
      <LeafProfileLoader row={1} card={true} isFetching={isLoading} />
      <ul>
        {trackingTilesData &&
          trackingTilesData.length > 0 &&
          trackingTilesData.map((downline, index) => (
            <li tabIndex={0} onClick={() => handleClick(BGT_TABS.TRACKING_TAB, downline)}>
              <TrackingTile key={index} {...downline} viewState={viewState} />
            </li>
          ))}
        {graduatedTilesData &&
          graduatedTilesData.length > 0 &&
          graduatedTilesData.map((downline, index) => (
            <li tabIndex={0} onClick={() => handleClick(BGT_TABS.GRADUATED_TAB, downline)}>
              <GraduatedTile key={index} {...downline} viewState={viewState} />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ABOProfileBGT;
