import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import { BRONZE_TAGS } from "../../reducers/model/bgtFilters";
import { BronzeGroupTrackingApiResponse, BGTEligibilityResponse, BgtMiniProfileApi } from "./bronzeGroupTracking.types";

type BaseRequestParams = {
  aff: string;
  abo: string;
  period: string;
  targetAffAbo: string;
};

type BgtMiniProfileApiRequestParams = {
  aff: string;
  abo: string;
  bonusPeriod: string;
  countryCode?: string;
};

type BgtEligibilityApiRequestParams = {
  affAbos: string[];
  period: string;
  countryCode: string;
};

type BGTOverviewPageRequestParams = {
  aff: string;
  abo: string;
  period: string;
  targetAffAbo: string;
  countryCode: string;
};

export type BGTOverviewPageResponse = {
  overview: BronzeGroupTrackingApiResponse;
  eligibility: BGTEligibilityResponse;
};

export const bronzeGroupTrackingAPI = createApi({
  reducerPath: "bronzeGroupTrackingAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getBronzeGroupTrackingApi: builder.query<BronzeGroupTrackingApiResponse, BaseRequestParams>({
      query: (params) => {
        const { abo, aff, period, targetAffAbo } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          TargetAffAbo: targetAffAbo,
        });

        return `/bronze-group-tracking/api/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getBgtMiniProfileApi: builder.query<BgtMiniProfileApi, BgtMiniProfileApiRequestParams>({
      query: (params) => {
        const { abo, aff, bonusPeriod, countryCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: bonusPeriod,
          IsoCountryCode: `${countryCode}`,
        });

        return `/bronze-group-tracking/api/mini-profile?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getBronzeGroupTrackingEligibilityApi: builder.query<BGTEligibilityResponse, BgtEligibilityApiRequestParams>({
      query: (params) => {
        const { affAbos, period, countryCode } = params;
        const queryParams = new URLSearchParams({
          AffAbos: `${affAbos}`,
          Period: period,
          IsoCountryCode: countryCode,
        });
        return `/bronze-group-tracking/api/eligibility?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getCombinedBronzeTrackingData: builder.query<BGTOverviewPageResponse, BGTOverviewPageRequestParams>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { aff, abo, period, targetAffAbo, countryCode } = args;

        const trackingQueryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          TargetAffAbo: targetAffAbo,
        });

        const overviewResult = await fetchWithBQ(`/bronze-group-tracking/api/overview?${trackingQueryParams}`);

        if (overviewResult.error) {
          return { error: overviewResult.error };
        }

        const data = overviewResult.data as BronzeGroupTrackingApiResponse;

        const affAbos = data.awardProgress.trackingTilesData
          .filter((aboTile) => aboTile.eligibilityTag === BRONZE_TAGS.IN_WINDOW)
          .map((a) => `${a.affNumber}-${a.aboNumber}`);

        let eligibilityResult: { data: BGTEligibilityResponse } = {
          data: {
            AboBronzeEligibility: [],
            CallResults: [],
            Error: null,
          },
        };

        if (affAbos.length > 0) {
          const eligibilityQueryParams = new URLSearchParams({
            AffAbos: affAbos.join(","),
            Period: period,
            IsoCountryCode: countryCode,
          });

          const eligibilityFetch = await fetchWithBQ(
            `/bronze-group-tracking/api/eligibility?${eligibilityQueryParams}`,
          );

          if (eligibilityFetch.error) {
            return { error: eligibilityFetch.error };
          }

          eligibilityResult = { data: eligibilityFetch.data as BGTEligibilityResponse };
        }

        return {
          data: {
            overview: overviewResult.data as BronzeGroupTrackingApiResponse,
            eligibility: eligibilityResult.data,
          },
        };
      },
    }),
  }),
});

export const {
  useGetBronzeGroupTrackingApiQuery,
  useGetBgtMiniProfileApiQuery,
  useGetBronzeGroupTrackingEligibilityApiQuery,
  useGetCombinedBronzeTrackingDataQuery,
} = bronzeGroupTrackingAPI;
