import { useEffect, useState } from "react";
import { getAbo, getAff, marketCode } from "../impersonation/util";
import {
  BGTOverviewPageResponse,
  useGetCombinedBronzeTrackingDataQuery,
} from "../../services/BronzeGroupTracking/bronzeGroupTracking";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { returnDefaultBronzeTrackingData } from "./bronzeGroupTrackingUtils";

export const FetchBronzeGroupTrackingOverviewData = (period: string, targetAffAbo?: string) => {
  const {
    data: payloadData,
    isFetching,
    isError,
  } = useGetCombinedBronzeTrackingDataQuery({
    aff: getAff(),
    abo: getAbo(),
    period,
    targetAffAbo: targetAffAbo || "",
    countryCode: marketCode(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bronzeTrackingData, setData] = useState<BGTOverviewPageResponse>({
    overview: returnDefaultBronzeTrackingData(),
    eligibility: { AboBronzeEligibility: [], CallResults: [], Error: null },
  });

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (payloadData && !isFetching && !isError) {
      setData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData?.overview?.errors || []);
      setErrorStatus(foundLambdaError);
    }
  }, [isError, payloadData, isFetching]);

  return { isLoading, bronzeTrackingData, errorStatus };
};
